<template>
  <div>
    <b-modal
      ref="view-time-slots"
      centered
      hide-footer
      hide-header
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      @hide="hideModal"
    >
      <div class="m-2">
        <!-- Venue Operating Hours -->
        <b-row>
          <b-col cols="11">
            <div class="text-center headingVenue">
              Selected Dates and Time Slots
            </div>
          </b-col>
          <div class="closediv">
            <b-button
              variant="transparent"
              class="closebtn"
              @click="hideModal()"
            >
              <feather-icon
                icon="XIcon"
                class="cursor-pointer"
                size="16"
              />
            </b-button>
          </div>
        </b-row>
        <b-row
          v-for="(date, index) in filteredDataArray"
          :key="index"
        >
          <b-col cols="12">
            <b-badge
              variant="success"
              pill
              class="dateslots"
            >
              {{ formatDateToDDMMYYYY(date.key) }}
            </b-badge>
          </b-col>
          <b-col cols="12">
            <b-card class="mt-1">
              <div class="ml-2">
                <b-row>
                  <b-col
                    v-for="(slot, index1) in date.value"
                    :key="index1"
                    cols="4"
                    md="3"
                    sm="6"
                    lg="3"
                    class="slotsrow mr-2"
                  >
                    <!-- Button on top right -->
                    <b-row class="text-center d-flex align-items-center">
                      <div
                        v-if="!shouldDisableCross"
                        class="closediv"
                      >
                        <b-button
                          variant="transparent"
                          class="btn-cross"
                          @click="deleteItem(date.key,index1)"
                        >
                          <feather-icon
                            icon="XIcon"
                            class="cursor-pointer"
                            size="16"
                          />
                        </b-button>
                      </div>
                      <b-badge
                        :style="{ backgroundColor: '#4e6ff5'}"
                        pill
                        class="pill-badge text-white"
                      >
                        <b-col class="colslots">
                          <span class="slots">{{ slot.start_time }} <feather-icon
                            icon="ArrowRightIcon"
                            size="16"
                            class="arrow"
                          /> {{ slot.end_time }}</span>
                        </b-col>
                      </b-badge>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-if="filteredDataArray.length <= 0">
          <b-col cols="12">
            <b-card class="mt-1">
              <b-col cols="12">
                <h4>
                  There are no time slots available
                </h4>
              </b-col>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCol,
  BBadge,
  BRow,
  BCard,
} from 'bootstrap-vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import Ripple from 'vue-ripple-directive'
import { formatDateToDDMMYYYY } from '@/common/global/functions'

export default {
  components: {
    BButton,
    BModal,
    BCol,
    BBadge,
    BRow,
    BCard,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    arrayOfDatesAndTimeSlots: {
      type: Array,
      required: true,
    },
    shouldDisableCross: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      spinner: false,
      dataArray: [],
    }
  },
  computed: {
    filteredDataArray() {
      const filtered = this.dataArray.filter(date => date.value.length > 0)
      return filtered.sort((a, b) => new Date(a.key) - new Date(b.key))
    },
  },
  watch: {
    timeSlots: {
      handler() {
        this.errorMessage = ''
      },
      deep: true,
    },
  },
  methods: {
    formatDateToDDMMYYYY,
    resetDataWhenModalClosed() {
      this.errorMessage = ''
      this.timeSlots = [{ start_time: null, end_time: null }]
      this.valueToBeSet = []
    },

    show() {
      this.dataArray = this.arrayOfDatesAndTimeSlots
      this.$refs['view-time-slots'].show()
    },

    hideModal() {
      this.resetDataWhenModalClosed()
      this.$refs['view-time-slots'].hide()
      this.$emit('addDatesWithTimeSlotsToArrayAfterViewingList', this.filteredDataArray)
    },
    deleteItem(slotDate, index) {
      this.dataArray.forEach(date => {
        if (date.key === slotDate) {
          date.value.splice(index, 1)
        }
      })
    },
  },

}
</script>
    <style src="vue-it-bigger/dist/vue-it-bigger.min.css"></style>
    <style lang="scss">
    .vs__dropdown-menu{
      max-height: 250px !important;
    }
    .form{
      padding-inline: 10px;
    }
    .add{
      position: relative;
    }
    .upload{
      position: absolute;
      bottom: 20px;
      right: 54px;
    }
    .head{
      font-size: 14px;
      line-height: 24px;
      color: #1f58b5;
      font-weight: 600;
      font-family: "Montserrat";
    }
    .spinner{
      text-align: center;
      z-index: 1070;
    }
    .compaignlabel{
      font-size: 10px;
      line-height: 18px;
      color: #323232;
      font-weight: 600;
      font-family: "Montserrat";
    }
    .headingVenue{
      font-size: 20px;
      color: black;
      font-weight: bolder;
    }
    .modal-body {
      max-height: 500px !important;
      min-height: 350px !important;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .modal-body::-webkit-scrollbar {
      width: 3px;
    }
    .modal-body::-webkit-scrollbar-track {
      background-color: #e4e4e4;
      border-radius: 50px;
    }

    .modal-body::-webkit-scrollbar-thumb {
      background-color: #4c92fc;
      border-radius: 50px;
    }
    .custom-modal{
      width: 800px !important;
    }
    .pac-container {
      z-index: 1041;
    }
    .btn-cross{
      color: white;
      position: absolute;
      border-radius: 50%;
      margin: 0px;
      padding: 0px;
      top: 7px;
      right: 15px;
      cursor: pointer;
    }
    .btn-cross:hover{
      color: #ea5455;
    }
   .card-body {
      padding: .5rem !important;
  }
  .dateslots{
    font-size: 15px;
  }
  .slots{
    font-size: 12px;
  }
  .arrow{
    margin-bottom: 2px;
  }
  .pill-badge{
    padding: 8px;
    padding-right: 25px
  }
  .slotsrow{
    margin-top: 10px;
    margin-bottom: 10px
  }
  .colslots{
    padding-top: 2px;
  }
    </style>
