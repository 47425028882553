const isSelectedDateIsGreaterThanTodayDate = (day, minDates) => {
  const selectedDate = new Date(day.id)
  const minDate = new Date(minDates)
  selectedDate.setHours(0, 0, 0, 0)
  minDate.setHours(0, 0, 0, 0)
  return selectedDate < minDate
}
const convertToSchedulerFormat = (array, deviceIds) => {
  const temparray = []
  array.forEach(element => {
    element.value.forEach(slots => {
      temparray.push({
        start_date_time: `${element.key} ${slots.start_time}`,
        end_date_time: `${element.key} ${slots.end_time}`,
      })
    })
  })
  return deviceIds.flatMap(deviceID => temparray.map(schedule => ({
    ...schedule,
    device_id: deviceID,
  })))
}

const convertedDataComingFromSchedulerApi = async data => {
  const groupedData = {}

  data.forEach(item => {
    const startDate = item.start_date_time.split('T')[0]
    const startTime = item.start_date_time.split('T')[1].split('.')[0]
    const endTime = item.end_date_time.split('T')[1].split('.')[0]

    if (!groupedData[startDate]) {
      groupedData[startDate] = []
    }

    const isDuplicate = groupedData[startDate].some(slot => slot.start_time === startTime && slot.end_time === endTime)
    if (!isDuplicate) {
      groupedData[startDate].push({
        start_time: startTime,
        end_time: endTime,
      })
    }
  })

  return Object.keys(groupedData).map(key => ({
    key,
    value: groupedData[key],
  }))
}
const getThreeDaysAheadDate = () => {
  const date = new Date()
  date.setDate(date.getDate() + 3)
  return date
}
const convertDateToISOString = async date => {
  const newDate = new Date(date)
  return newDate.toISOString()
}

export {
  isSelectedDateIsGreaterThanTodayDate,
  convertToSchedulerFormat,
  convertedDataComingFromSchedulerApi,
  getThreeDaysAheadDate,
  convertDateToISOString,
}
